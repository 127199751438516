em{
    font-style: normal;
}
.layout.rankingprize{
    padding: 290px 0 50px;
}
.ranking-time .ant-statistic{
    color:#fff;
}
.rankingprize-box .ant-tabs-bar{
    border-bottom: none;
}
.rankingprize-box .ant-tabs-nav-container{
    font-size:16px;
}
.rankingprize-box .ant-tabs-nav .ant-tabs-tab-active,.ant-tabs-nav .ant-tabs-tab:hover{
    color:#fd6b59;   
}
.rankingprize-box .ant-tabs-ink-bar{
   background-color: transparent;  
}
.rankingprize-box .ant-tabs-nav .ant-tabs-tab{   
    padding: 0px 28px;
    text-align: left;
    margin:0;
}
.rankingprize-box  .ant-tabs-nav>div>div.ant-tabs-tab {
    border-right: 1px solid #d0d2df;
    /* padding-right: 28px; */
}
.rankingprize-box  .ant-tabs-nav>div>div.ant-tabs-tab:last-child{
    border:none;  
}
.rankingprize-p{
    font-size:28px;
    padding:25px 0 25px;
}
.rankingprize-card ul{ 
    padding:20px 0;
}
.rankingprize-card ul li{
    width:25%;
    display:inline-block;
    padding:0 15px;
    margin:10px 0 35px;
    color:#fff;
    font-size:14px;
    position: relative;
}
.rankingprize-card-box{
    background:#fff;
    position: relative;
    border-radius: 8px;
    color:#1D3A73;
    box-shadow: 0px 0px 15px rgba(29,58,115,.15);
    padding: 15px 0;
}
.rankingprize-card-top{
    width:70px;
    height:70px;
    border-radius:100%;
    background:#FFD859;
    position: absolute;
    top:-35px;
    left:50%;
    margin-left:-35px; 
    display: flex;
    justify-content: center; 
}
.rankingprize-card-intro{
    padding:40px 10px 20px;
}
.rankingprize-card-intro h4{
    margin:10px 0;
    font-size:32px;
    font-weight: 600;
    color:#1D3A73; 
}

.rankingprize-box .ant-tabs{
    overflow:inherit;
}
.rankingprize-table-box ul li{
    width:48%;
    padding:0 10px;
    display: inline-table;
    margin-bottom:40px;
}
.rankingprize-table-box .rankingprize-table-item ul li{
    margin:5px 0;
}
.rankingprize-table-item{
    position: relative;
}
/* .rankingprize-table .bole-table-content ul li:first-child{
    top: -0px;
    left: -10%;
    width: 120%; 
    margin:-10px 0 5px;   
} */
.rankingprize-table .bole-table-top ul li:nth-child(1), .rankingprize-table .bole-table-content ul li span:nth-child(1){
   width:25%;
}
.rankingprize-table .bole-table-top ul li:nth-child(2), 
.rankingprize-table .bole-table-content ul li span:nth-child(2){
    width:40%;
}
.rankingprize-table .bole-table-top ul li:nth-child(3), 
.rankingprize-table .bole-table-content ul li span:nth-child(3){
    width:35%;
}
/* .rankingprize-table  .bole-table-content ul li:first-child div{
    width:83%;
} */
.rankingprize-table-box{
    margin: 0 auto;   
}
.rankingprize-table-ul{
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
}
.time-bg{
    background: linear-gradient(0deg,#F9ECD8,#fff);    
    padding:5px 10px;
    font-size:16px;
    display:inline-block;
    box-shadow: 0 1px 15px rgba(0,0,0,.3);
    border-radius:4px;
    margin:0 3px;
    color:#FF1957;
    font-size:18px;
    font-weight:600;
}
@media screen and (min-width:768px) and (max-width:1000px){
    .rankingprize-table-box ul li{
        width:50%;
    }
    .rankingprize-table .bole-table {
        width: 93%;
    }
    /* .rankingprize-table .bole-table-content ul li:first-child {
        top: -0px;
        left: -11%;
        width: 122%;
    } */
}
@media screen and (max-width:767px){
    .layout.rankingprize{
        padding: 120px 0 50px;
    }
    .rankingprize-card ul li{
        width:50%;
    }
    .rankingprize-box .ant-tabs-nav .ant-tabs-tab{      
        padding: 0px 16px 0 15px;
    }
    .rankingprize-table-box ul li{
        width:100%;
    }
}