.download-btn-box{
    display:flex;
    justify-content: center;
}
.download-btn{
    /* width:40%; */
    min-width: 120px;
    padding:20px 0;
    text-align:left;
    background:#1B8CFF;
    color:#fff;
    margin:0 15px;
    border-radius:8px;
    display:flex;
    justify-content: center; 
    transition: transform .2s, box-shadow .5s;  
}
.download-btn:hover{
    cursor: pointer;
    transform: scale(1.04);
}
.download-btn h3{
    color:#fff;
    font-weight:600;
    margin:0;
    font-size:20px;
    line-height: 26px;
}
.download-btn h3 span{
   font-size:16px;
   font-weight:500;
   padding-left:5px;
}
.download-btn img{
    margin-right:10px;
    max-width:50px;
}
.download-content{
    background:#fff;
}
.download-box{
    /* padding-top:150px; */
}
.download-bg{
    position: relative;
    top:-170px; 
    width:100%;
}
.download-btn-box-left{
    width: 50%;
    text-align: left;
}
.download-btn-tip{
    margin-left:20px;
    padding:5px 0;
    color:#666;
}
@media screen and (max-width: 767px) { 
    .download-btn-box{
        flex-flow: column;
    }
    .download-btn{
        width:100%;       
        margin:0 auto 10px;
    }
    .download-banner{
        padding-top: 100px;
        padding-bottom:80px;
     }
     .download-bg{
         top:-70px;
     }
     .download-btn-body{
         margin-top:-70px;
     }
     .download-btn-box-left{
         width:100%;
         margin-bottom:20px;
     }
}
@media screen and (min-width: 768px){
    .download-banner{
        padding-top: 120px;
        padding-bottom:180px;
     }
     .download-btn-body{
        margin-top:-170px;
    }
}