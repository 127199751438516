.common-content{    
    background: #fff;
    min-height:400px;
}
.search-box{
    position: relative;
    width:100%;
    height: 42px;
    color: #D5D5D5;
    display: inline-block;
    z-index: 3; 
    display:flex;
    align-items: center; 
    transition: transform .1s ease,opacity .2s ease;
}
.search-input{
    width:100%;
    position: relative;
    display: inline-block;
    background: #fff;
    border: none;
    line-height: 40px;
    color: #3B3B3B;
    letter-spacing: 1.1px;
    z-index: 1;
    padding-right: 40px;
    border-radius: 4px;
    text-indent:1em;
    
}
.search-icon{
    width: 24px;
    height: 24px;
    display: inline-block;
    background: url(/image/icon/search.png) no-repeat center/cover;
    position: absolute;
    /* top: 15px; */
    right: 10px;
    z-index: 1;
}
.search-list{
    position: absolute;
    width:100%;
    height:100px;
    background:#fff;
    top:100%;
    box-shadow: 0 -1px 9px -2px rgba(69,69,69,.5);
    border-radius:4px;
    overflow-y:auto;
    overflow-x:hidden;
}
.search-list-ul{
    padding:10px;
    font-size:14px;
    color:#777;
    text-align:left;
}
.search-list-ul li{
    padding:5px 0;
}
.banner-title-h3{
    font-size:40px;
    color:#fff;
    letter-spacing: 3px;
}
.knowledge-content.layout{
    padding:50px 0 0;
}
.knowledge-list{
    text-align:left;
    margin-top:40px;
}
.knowledge-list h3{
    font-weight: 600;
}
.knowledge-list strong{
    font-weight: normal;
}
.knowledge-list ul li{
    display: flex;
    justify-content: space-between;    
    position: relative;
    /* height:40px;   */
    font-size:15px;   
    color:#333;
}
.space{
    height:20px;
    display:inline-block;
    flex:1;
    border-bottom-width:1px;
    border-bottom-style:dashed;
    border-bottom-color:#d2d1d1;
}
.knowledge-list ul li strong{ 
    background: #fff;
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 7px;
    padding-right:5px;
}
.knowledge-list ul li em{  
   background: #fff;
   padding-left:5px;
   padding-top: 7px;
   color:#999;
   max-width:40%;
   text-align:right;
}
.hot-icon{
    display:inline-block;
    width:18px;
    height:18px;
    background-image:url(/image/icon/hot.svg);
    background-position: center;
    background-size:100%;
    background-repeat: no-repeat;
    margin-left:5px;
}
.knowledge-details-body{
    display:flex;
    justify-content: space-between;

}
.knowledge-left{
    width:280px;   
    margin-right:20px;
}
.knowledge-right{
    flex:1;
    border-left:1px solid #d2d1d1;
}
.knowledge-left .search-box{
    border:1px solid #d2d1d1;
} 
.knowledge-menu{
    text-align:left;
    margin-top:15px;
}
.knowledge-menu-title{
    font-size:16px;
    font-weight: 600;   
}

.knowledge-body-title{
    color:#1D3A73;
    letter-spacing: 1px; 
}
.knowledge-body-title h2{
    font-size:36px;
    color:#1D3A73;
    margin:0 0 5px;
}
.knowledge-body-title p{
    font-size:16px;
}
.knowledge-right-body{
    text-align:left;
    padding:0 20px 20px;
}
.knowledge-right-body p{
    line-height:25px;
    font-size:16px;
}
.knowledge-right-body p{
    line-height:30px;
    font-size:15px;
    text-indent:2em;
    margin-bottom:10px;
}
.knowledge-right-title{
    font-size:24px;
    font-weight:600;
    color:#333;
}
.knowledge-details-body .search-icon{
    width:18px;
    height:18px;
}
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
    border-bottom:1px solid #d2d1d1;
}
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
    border:none !important;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
    height:35px !important;
    line-height:35px !important;
    /* margin-bottom:0 !important; */
    padding-left:38px !important;
}
@media screen and (max-width:767px){
    .banner-title-h3{
        font-size:24px;
    }
    .knowledge-details-body{
        flex-flow: column;
    } 
    .knowledge-left{
        width:100%;
        margin-right:0;
    }  
    .knowledge-right{
        border:none;
        margin-top:20px;
    } 
    .knowledge-body-title h2{
        font-size:24px;
    } 
    .knowledge-bottom .banner-btn{
        height:40px;
        line-height:40px;     
    }
}