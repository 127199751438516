.home-banner.bolePrize{
    background: -webkit-linear-gradient(90deg,#FE4E71,#FE7C53,#FF575E );
    background: -o-linear-gradient(90deg,#FE4E71#FE7C53,#FF575E);    
    background: -moz-linear-gradient(90deg,#FE4E71,#FE7C53,#FF575E);    
    background: -moz-linear-gradient(90deg,#FE4E71,#FE7C53,#FF575E);    
    background: linear-gradient(90deg,#FE4E71,#FE7C53,#FF575E); 
}
.horse{
    position: absolute;
    left:0px;
    top:90px;
}
.banner-content {
    display: flex;
    align-items: center;
    flex-flow: column;
    position: relative;
}
.banner-content > div{
    margin:10px 0;
}
.banner-content p{
    font-size:14px;
    letter-spacing: 1px;
    padding-bottom: 8px;  
}
.banner-title-top p{
    font-size:22px;
    letter-spacing: 3px; 
    padding:0px 0 10px;
}
.banner-title-top h2{
    font-size:48px;
    letter-spacing: 3px;
    font-weight: 600;
    color:#fff;
}
.home-banner.bolePrize .banner-btm{
    bottom:-1px;
}
.banner-btn{
    width: 200px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    font-size: 16px;  
}
.banner-btn.copy-btn{
    position: relative;
    z-index: 2;
    color: #FE6D6A;
    background: #fff;
}
.banner-btn.copy-btn img{
    vertical-align: text-top;
    margin-right:5px;
}
.banner-btn.copy-btn:hover{
    background:#fff;
}
.banner-btm2{    
    width: 100%;
    position: absolute;
    bottom: -255px;
    right: 0;
}
.layout.bolePrize{
    padding: 240px 0 50px;
    background-image:url('/image/bole-bg.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;    
}
.title-intro{
    font-size:24px;
    color:#1D3A73;
}
.title-intro em{
    font-style: normal;
    color:#FE4E71;
}
.bole-table{
    width: 100%;
    background: #FFF5F1;
    box-shadow: 0px 1px 7px rgba(255, 160, 173,.55);
}
.bole-table-top{
    width:100%;
    margin:0 auto;
    /* background: -webkit-linear-gradient(-90deg,#FE7C53,#FF575E,#FE4E71 );
    background: -o-linear-gradient(-90deg,#FE7C53,#FF575E,#FE4E71);    
    background: -moz-linear-gradient(-90deg,#FE7C53,#FF575E,#FE4E71);    
    background: -moz-linear-gradient(-90deg,#FE7C53,#FF575E,#FE4E71);    
    background: linear-gradient(-90deg,#FE7C53,#FF575E,#FE4E71); */   
    /* background: linear-gradient(-180deg,#FF677C,#FFA0AD); */
    padding:20px 0 0px;   
    color:#fa445d;
    border-radius:8px 8px 0 0;
    position: relative;    
    background: #FFF5F1;
    
}
.bole-table-top h4{
    color:#fa445d;
    font-size:24px;
    letter-spacing: 3px;
    margin-bottom: 20px;
    font-weight: 600;
}
.bole-table-top h4 img{
    max-width:45px;
    vertical-align: -18px;
}
.bole-table-top ul{
    padding:0 20px;
    overflow: hidden;
    margin-bottom: 5px;
}
.bole-table-top ul li{
    float: left;
    text-align:center;
    font-size:18px;
    font-weight: 600;
}

.bole-table-top ul li:nth-child(1),.bole-table-content ul li span:nth-child(1){
    width:15%;
}
.bole-table-top ul li:nth-child(2),.bole-table-content ul li span:nth-child(2){
    width:25%;
}
.bole-table-top ul li:nth-child(3),.bole-table-content ul li span:nth-child(3){
    width:35%;
}
.bole-table-top ul li:nth-child(4),.bole-table-content ul li span:nth-child(4){
    width:25%;
}

.bole-table-content{
    width:100%;
    margin:0 auto;
    /* background:#FFF3EE; */
    /* background: linear-gradient(-180deg,#FFA0AD,#fff); 
    box-shadow: 0px 1px 7px rgba(255, 160, 173,.55); */
    background: #FFF5F1;
    color:#FA445D;
}

.bole-table-content ul{
    padding:0px 20px 20px;
}
.bole-table-content ul li{ 
    width:100%; 
    text-align:center;
    font-size:13px;   
    background:#fff;
    /* box-shadow: 0 0px 7px rgba(0,0,0,.15); */
    margin:10px 0;
    padding:10px 0;
    font-weight:500;
    position: relative;
    box-shadow: 0px 1px 7px rgba(255, 160, 173,.55);
}
.bole-table-content ul li span img{
    width:30px;
    display: inline-block;
    margin:0 5px 0 0;
    height:30px;
    border-radius:100%;
}
.bole-table-content ul li span p{
    display: inline-block;
}
.table-ava{
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;  
    border-radius: 100%;
    margin-right: 5px;
    vertical-align: -9px;
}
/* .bole-table-content ul li:first-child span img{
    max-width:35px;   
} */
/* .bole-table-content ul li:first-child{
    margin: -10px 0 10px;  
    color: #fff;
    position: relative;
    top: -0px;
    left: -10%;
    width: 120%;  
    background: #FA445D;
    box-shadow: 0 0px 4px rgba(0,0,0,.3);
    border-radius: 4px 4px 0 0;
    padding:15px 0;
} */
/* .bole-table-content ul li:first-child::before,.bole-table-content ul li:first-child::after{
    content: '';
    position: absolute; 
    bottom: -14px;  
    border-width: 0 17px 17px;
    border-style: solid;
    border-color: transparent transparent #D34741;
    transform: rotate(45deg);    
}
.bole-table-content ul li:first-child::before{   
    left: 1px;
}
.bole-table-content ul li:first-child:after{   
    right: 1px;
    transform: rotate(-45deg);
} */
.bole-table-content ul li span{
    display: inline-block;
}
.bole-table-content ul li div{
    width:100%;
    display: flex;
    align-items: center;
}
/* .bole-table-content ul li:first-child div{
    width:92%;
    margin:0 auto;
} */
.find-bole ul li{
    display:inline-block;
}
.find-bole ul li span{
    width: 50px;
    height: 50px;
    display: inline-block;
    margin: 0 4px 5px 0;
    cursor: pointer;
    overflow: hidden;    
}
.find-bole ul li span img{
    border-radius: 100%;
}
.sharebill{
    background: linear-gradient(-180deg,#EBEBEB ,#fff,#fff);
    /* margin:50px 0 0;   */
    background:#fff;
    padding: 30px 0 50px;
}
.bill-box{
    display:flex;
    justify-content:center;  
    margin: 0 -15px;
    position: relative;
}
.bill-left{
    /* flex:1;  */
    float:left;
    /* border:1px solid red;  
    background-image:url('/image/bole-bill01.png'); 
    background-repeat: no-repeat;
    background-position: center; */
    position: relative;
}
.qr-code{
    position: absolute;
    bottom: 74px;
    left: 47px;
    width: 114px;
    height: 114px;    
}
/* .bill-left img{
    width: 100%;
} */

.bill-right{
    width:50px;
    margin-left:5px;
    position: absolute;
    top: 10px;
    right: 10px;    
}
.bill-left-box{
    display:flex;
}
.bill-pic{
    width:50%;
    height:100%;   
}
.bill-left-intro{
    width:50%;   
}
.bill-right ul{
    margin-top: 16px;
    margin-left: -15px;
}
.bill-right ul li{
    width:50px;
    height:50px;
    background:#fff;
    display: flex;
    justify-content: center;
    margin-bottom: 2px;
    cursor:pointer;
    background-repeat: no-repeat;
    background-position: center center;   
}

.bill-right ul li:nth-child(1){
    background-image:url(/image/icon/weibo.svg);   
}
.bill-right ul li:nth-child(2){
    background-image:url(/image/icon/qq.svg);    
}
.bill-right ul li:nth-child(3){
    background-image:url(/image/icon/qzone.svg);  
}
/* .bill-right ul li:nth-child(4){
    background-image:url(/image/icon/copy-icon.png);  
} */
/* .bill-right ul li:hover{
    background-color:#FF1957;
}
.bill-right ul li:nth-child(1):hover{
    background-image:url(/image/icon/weibo02.svg);  
 }
 .bill-right ul li:nth-child(2):hover{
    background-image:url(/image/icon/qq02.svg);  
 }
.bill-right ul li:nth-child(3):hover{
   background-image:url(/image/icon/qzone02.svg);  
} */
.bill-btn{
    width:185px;
    height:50px;
    line-height:50px;
    border-radius:35px;
    background:#FF1957;
    color:#fff;
    font-size:16px;
    text-align:center;    
    margin:25px auto 0;
}
.bill-btn a{
    color:#fff;
}
.bill-btn img{
    margin-right:10px;
}
@media (min-width: 768px){
    .home-banner.bolePrize{
        padding-top: 120px;
        padding-bottom: 120px;
    }
    /* .bole-table-content ul li:first-child{
        left: -4.3%;
        width: 108.5%;
        font-weight: 600;
    } */
    .bole-table-content ul li{
        font-size:16px;
    }
    .bill-right{
        margin-left:0;
        float: left;
        position: initial;
    }
    .bill-right ul{
        margin-top: 34px;        
    }
    .bill-btn{
        margin:0 auto;
    }
    .qr-code{
       bottom: 93px;
       left: 90px;
       width: 178px;
       height: 178px;
    }
}
@media screen and (min-width: 640px) and (max-width:767px){
    .banner-title-top h2{
        font-size:24px;
    }
    .banner-title-top p{
        font-size:18px;
    }
    .banner-btm2{       
        bottom:-240px;
    }
    .banner-btm2 img{
        max-width:600px;
    }
}
@media screen and (max-width:639px){
    .banner-title-top h2{
        font-size:24px;
    }
    .banner-title-top p{
        font-size:18px;
    }
    .banner-btm2{       
        bottom:-100px;
    }
    .banner-btm2 img{
        max-width:320px;
    }
    .layout.bolePrize{
        padding: 100px 0 50px;
    }
    .bole-table-top ul{
        padding:0 10px;
    }
    .bole-table-top ul li{
        font-size:12px;
    }
    .bole-table-content ul{
        padding:0px 10px 10px;
    }
    /* .bole-table-content ul li:first-child div{
        width:83%;
    } */
    .title-intro{
        font-size:16px;
    }
    
}