.App {
    text-align: center;
    box-sizing: border-box;
}

*:not(input) {
    user-select: none;
    outline: none;
}
/* 触摸滚动 */
* {
    -webkit-overflow-scrolling: touch;
}
.app{
    position: relative;
    /* overflow: hidden; */
}
.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.app-content {
    /* position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; */
    /* overflow: auto; */
    background:#fff;
    overflow-x: hidden;
}
.loading-mask{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}
.ant-back-top {
    bottom: 100px;
  }
.ant-back-top-inner {
    height: 40px;
    width: 40px; 
    line-height: 34px; 
    border-radius: 4px;
    background-color: #fff;
    color: #fff;
    text-align: center;
    box-shadow: 0 0 5px rgba(0,0,0,.5);
}
@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}