*{
    padding:0;
    margin:0;
    box-sizing: border-box;
}
p,h4{
    padding:0;
    margin:0; 
}
ul,li{
    list-style-type: none;
}
img{
    max-width:100%;
    height:auto;
}
.clearfix:after {
    content: '';
    display: block;
    clear: both;
}
.layout{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align:center;
    padding:50px 0;
}
.prow{
    margin:0 -15px;
}
.container{
    width:1100px;  
    margin:0 auto;   
    padding-left:15px;
    padding-right:15px; 
    position: relative;
}
.homepage {
    color: rgba(67, 67, 67, 1);
}
.home-banner {
    position: relative;
    padding: 140px 0 60px;
    /* background: linear-gradient(135deg,#1da2c5,#4642e9,#3c0684); */
    text-align: center;
    color: #fff;
    background-image: url(/image/banner-home2.png);
    background-position: top;
    /* background-size:100%; */
    background-repeat: no-repeat;
}

.banner-title{   
    position: relative;
    z-index: 1;
    max-width: 400px;
    margin: 0 auto;
}
.banner-title h2{
    font-size:42px;
    color:#fff;    
    text-align:left;
    line-height: 1.08333333;
    letter-spacing: 5px;
}
.banner-title p{
    font-size:14px;
    text-align:left;
    line-height:25px;
    margin:10px 0;
}
.banner-btn{
   width:200px;
   height:64px;
   line-height:64px;
   text-align:center;
   font-size:14px;
   color:#fff;
   background:#FCB42B;
   border-radius:35px;
   cursor:pointer;
   margin:20px 0;
   text-align:center;
}
.banner-btn:hover {
    background: #ffc20a;
}
.home-person{
    bottom: -168px;
    left: 0;
    right: 0;
    overflow: hidden; 
}
.home-person, .person-illust {
    position: absolute;
    height: 533px;
}
.person-illust{
    bottom: 0;
    right: -90px;
    width: 600px;
    background: url(/image/01.png) 0 100% no-repeat;
    background-size: 100% 100%;
}

.home-about.recording{  
    background: linear-gradient(0deg, #fff,#E9E9E9); 
    padding-top:40px;
}

.home-video{
    position: relative;   
    margin: 0 auto;
    z-index: 1;
}
.video {
    position: relative;
    width: 100%;
    max-width: 360px;
    margin: 30px auto 0;
    max-width: 800px;
    margin: 0 auto;
}
.video-bg{   
    position: relative;
    display: block;
    background-image: url(/image/video-bg.png);
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
    border-radius: 10px;
    box-shadow: 0 20px 35px rgba(71,80,89,.15);
}
.video-bg::before{
    content: "";
    display: block;
    padding-top: 62.5%;
    padding-top: 59.375%;
}
.video-icon{
    position: absolute;
    top: 50%;
    left: 50%;    
    transition: opacity .2s ease-out,-webkit-transform .2s ease-out;
    transition: transform .2s ease-out,opacity .2s ease-out;
    -webkit-transform: translate3d(-50%,-50%,0) scale(.95);
    transform: translate3d(-50%,-50%,0) scale(.95);
    opacity: .8;    
}
.video-icon:hover{
    opacity: 1;
    transform:translate3d(-50%,-50%,0) scale(1);
    -webkit-transform:translate3d(-50%,-50%,0) scale(1);
   
}
.home-about {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-top: 50px; */
    padding-bottom: 50px;
    text-align:center;  
}
.customers-list ul,.support-list ul{
    margin:0 -10px;
}
.customers-list ul li,.support-list ul li{
    width:25%;
    padding: 0 10px;
    float: left;
    margin-bottom:15px;
}
.customers-list ul li{
    width:25%;
}
.support-list ul li{
    width:20%;
    color:#1D3A73;
    font-size:14px;
    height:130px;
}
.support-list ul li p span{
    display:block;
}
.img-box{    
    min-height:80px;
    display: flex; 
    align-items: center;
    justify-content: center;
}
.list-item p{
    padding-top:20px;
}
.home-content-title{
    margin-bottom: 40px; 
}
.title-tip {
    font-size: 14px;
    color: red;
    margin: 0;
}
.title {
    font-size: 36px;
    color: #1D3A73;
    letter-spacing: 5px;
}

.features-content img{
    -webkit-transform: scale(.8);
    transform: scale(.8);
    /* -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%; */
}
.triangle{
    height:30px;
}
.triangle, .triangle svg {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 20!important;
}
.triangle svg {
    top: 0;
    height: 100%;
}
.triangle--bottom {
    bottom: -1px;
}
.triangle--gray svg {
    fill: #f2f5f7;
}
.home-about.like{
    padding-top: 50px; 
    padding-bottom: 0;
}
.volunteer-bg{
    background-image:url('/image/volunteer.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    width: 100%;  
    padding-bottom: 20%;
}
.banner-btn.join-btn{
    margin:30px auto -40px;   
}
.home-process{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.home-process li{
    width:160px;
}
.home-process li div{
    position: relative;
}
.process-arrow{
    width: 55px !important;
    height: 66px;
    display: inline-block;
    background: url(/image/icon/process-arrow.svg) 50% 21px no-repeat; 
}
.home-process li p{
    color:#1D3A73;
    font-size:15px;
    padding-top:10px;
    font-weight:600;
}
.swiper-slide{   
    height:280px !important;
    /* box-shadow: 0 1px 4px rgba(71,80,89,.3); */
    border-radius:4px;
    background: #f2f5f7;
}
.swiper-item{
    padding:0 25px;
}
.slide-ava{
    width:95px;
    height:95px; 
    margin-top:-40px; 
    margin-left:35%; 
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 100%;
    margin-bottom: 25px;
}
.intro{
    font-size:14px;
    color:#515869;     
}
.swiper-item h4{
    font-size:18px;
    color:#0283FD;
    padding-top:40px;
}
.h-form{
    font-size:14px;
    color:#9399A0;
}
.swiper-container{
    padding:20px 0 0 !important;
}
.home-slider .swiper-container-horizontal>.swiper-pagination-bullets, .home-slider .swiper-pagination-custom, .swiper-pagination-fraction{
    bottom:-2px;
}
/* .swiper-slide{
    transform: scale(.8);
}
.swiper-slide-active{
    transform: scale(1)    
} */
.home-phone-process{
    padding-bottom:20px;
}
.home-phone-process img{
    max-width:160px;
}
.banner-btm{  
    background-repeat: no-repeat;   
    width: 100%;
    height: 70px;   
    position: absolute;   
    bottom: -1px;
    /* bottom: 18px; */
    right: 0;   
    background-size: 100% 100%;
}
.top-circle{
    position: absolute;
    top:20%;
    left:15%;  
}
.top-circle img{
    max-height:400px;
}
.home-about.process{
    padding-top:40px;
}
.home-about.ranking{
    background: linear-gradient(0deg, #fff,#ebf6fa); 
}
.home-ranking-box{
    width:100%;
    padding:40px 0 60px;
    background-image: url(/image/home-ranking-bg.png);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% 100%;
}
.ranking-box{
    margin:0 auto;
    display:flex;
    justify-content: space-between;
}
.ranking-left,.ranking-right{
    width:45%;
    float:left;     
}

/* .ranking-content .bole-table-content ul li:first-child{
    top: -0px;
    left: -10%;
    width: 120%;
    background: #1B8CFF;
} */
/* .ranking-content .bole-table-content ul li:first-child::before, .ranking-content .bole-table-content ul li:first-child::after{
    border-color: transparent transparent #1370DD; 
} */

.ranking-box .bole-table {   
    background: #CAE4FF;
    box-shadow: 0px 1px 7px rgba(202, 228, 255,.55);
    border-radius: 8px;
}
.ranking-content .bole-table-top{    
    /* background: linear-gradient(-180deg,#3E9EFF,#77BAFF);
    box-shadow: 0px 0px 15px rgba(119,186,255,.55); */
    background: #CAE4FF;
    color:#1B8CFF;
}
.ranking-content .bole-table-content{
    /* background:linear-gradient(-180deg,#77BAFF,#fff);  
    box-shadow: 0px 0px 35px rgba(119,186,255,.55); */
    background: #CAE4FF;
    color:#1B8CFF;
}
.ranking-box .bole-table-top h4{
   color:#1B8CFF;
}
.ranking-box  .bole-table-content ul li{
    box-shadow: 0px 1px 7px rgba(108, 172, 239, 0.55);    
}
.ranking-content .bole-table-top ul li:nth-child(2), 
.ranking-content .bole-table-content ul li span:nth-child(2){
    width:50%;
}
.ranking-content .bole-table-top ul li:nth-child(3), 
.ranking-content .bole-table-content ul li span:nth-child(3){
    width:35%;
}
/* .ranking-content  .bole-table-content ul li:first-child div{
    width:83%;
} */
.home-volunteer{
    top:0;
}
/* .download-desk {
    position: fixed;
    bottom: 20px;
    right: -15px;
    z-index: 10;
    cursor:pointer;
} */
@media (min-width: 768px){
    .home-banner{
        padding-top: 180px;
        padding-bottom: 100px;
    }
    .triangle {
        height: 50px;
    }  
    .banner-title{
        margin: -50px 0 0;
    } 
    /* .home-video{
        margin: 50px 0 -400px;  
    }   */
    .video {
        margin-top: 60px;
    }
    .home-phone-process{
        display:none;
    }  
    .banner-btm{
        background-image: url(/image/banner-btm-big2.png); 
    }
    .home-volunteer{
        top:100px;
    }
    
}
@media (min-width: 1000px){
    .triangle {
        height: 70px;
    }
    .banner-title{
        margin-top: 0;
        /* max-width: 475px; */
        max-width: 800px;
    }
    .home-person{
        /* bottom: -175px; */
        bottom: -150px;
        right: 0;
        height: 700px;
        margin-left: -30px;
        margin-right: -30px;
    }
    .person-illust{
        width: 787px;
        height: 700px;
        right: -50px;
    }
    .home-video{
        position: relative;
        z-index: 1;
        max-width: 800px;
        margin: 0 auto;  
    }
    .video {
        margin: 30px 0 0 auto;
    }
}
@media (min-width: 1025px){
    .features-content img{
        opacity: 0;
        transition: opacity 1s,-webkit-transform .8s;
        transition: opacity 1s,transform .8s;
        transition: opacity 1s,transform .8s,-webkit-transform .8s;
        will-change: transform,opacity;
    }
    .features-content img.entered{
        -webkit-transform: translateX(0)!important;
        transform: translateX(0)!important;
        opacity: 1;
    }
}
@media (min-width: 1400px){
    .banner-title h2{
        font-size:72px;
    }
}
@media screen and (max-width: 1200px) {
    .container {
       width:100%;
    }
}
/* @media (min-width: 1000px) and (min-width: 760px){
    .home-banner{
        padding-top: 190px;
        padding-bottom: 190px;
    }
    .home-person{
        bottom:0;
    }
    
} */

@media screen and (max-width: 767px) {
    .title{
        font-size:24px;
    }
    .home-banner{
        padding-top: 100px;
        padding-bottom: 50px;
    }
    .banner-title h2 {
       font-size:24px;
       text-align:center;
    }
    .banner-title p{
        text-align:center
    }
    .banner-btn{
        margin:20px auto;
    }
    .home-about,.home-about.like{
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .home-content-title{
        margin-bottom:15px;
    }
    .home-person{
        display:none;
    } 
    .customers-list ul li,.support-list ul li{
        width:50%;
    }
    .support-list ul li{
        margin-bottom: 15px !important;
    }   
    .home-process{
        display:none;
    }
    .home-process li{
        width: 50%;
        float: left;
        margin-bottom: 20px;
    }
    .process-arrow{
        display:none;
    }
    .home-process li:last-child{
        width:100%;
    }   
    .banner-btm{
        background-image: url(/image/banner-btm.png);
        bottom:-1px;
    }
    .top-circle img{
        max-height:300px;
    }
    .ranking-box{
        flex-flow: column;
    }
    .ranking-left,.ranking-right{
        width:100%;
        max-width: 100%;      
    }
    .ranking-right{
        margin-top:15px;
    }
    .home-about.ranking{
        background-size: contain;
    }
    /* .download-desk{
        display:none;
    } */
}
@media screen and (min-width:768px) and (max-width:1399px){
    .banner-btm{
        bottom:-1px;
    }
}