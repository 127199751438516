.home-footer {
    height: 80px;
    background:#F2F5F7;
    display: flex;
    align-items: center;
}
.home-footer-box{
    width:100%;
    font-size:14px;
    color:#879099;
}
.home-footer-left{
    width:60%;
    float:left;
    padding:0 5px;   
    text-align: left;
}
.home-footer-middle{
    width:40%;
    float:left;
    padding:0 5px;
    text-align:right;
}
.home-footer-right{
    width:40%;
    float:left;
    padding:0 5px; 
    margin-top:10px;
    text-align:right;
}
.home-footer-right ul{
    display: flex;
    margin: 0 -5px;
    justify-content: flex-end;    
}
.home-footer-right ul li{
    float: left;
    padding: 0 5px;
    text-align: center;
}
.home-footer-right ul li img{
    max-width:100px;
}
.foot-btn{
    width:100%;
    height:40px;
    line-height:40px;
    color:#fff;
    background: #1890ff;
    text-align:center;
    display:block;
    margin-top:10px;
    cursor:pointer;
}
.foot-btn:hover{
    color:#fff;
}
@media screen and (max-width:992px){
    .home-footer{
        height:auto;
        padding:20px 0;
        /* position: inherit; */
    }
    .home-footer-left,.home-footer-middle,.home-footer-right{
        width:100%;
    }
    .home-footer-left{
        text-align: center;
    }
    .home-footer-middle{
        margin-top:0px;
        text-align:center;
    }
    .home-footer-right ul{
        justify-content: space-around;
    }
    /* .home-footer-right ul li{
        padding:0 15px;
    } */
}