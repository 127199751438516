.music-panel-container{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0,0,0,.2);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.story-theme-panel, .time-option {
    position: absolute;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}
.story-theme-panel {
    top: 20px;
    left: 20px;
    bottom: 20px;
    width: 380px;
}
.music-panel {
    width: 420px;
    -webkit-box-shadow: 0 2px 10px 0 rgba(71,110,87,.5);
    box-shadow: 0 2px 10px 0 rgba(71,110,87,.5);
    border-radius: 8px;
}
.music-panel-head {
    position: relative;
    height: 50px;
    line-height: 50px;
    color: #666;
    font-size: 20px;
    text-align: center;
    background: #fff;
    border-radius: 8px 8px 0 0;
}
.music-panel-body {
    max-height: 500px;
    overflow: auto;
    background: hsla(0,0%,100%,.9);
    padding: 20px;
}
.story-theme-panel>.music-panel-body {
    -ms-flex: 1 1;
    flex: 1 1;
    max-height: unset;
}
.music-panel-body>ul {
    list-style: none;
    padding: 0;
    color: #666;
}
.story-theme-type {
    line-height: 30px;
    color: #5dac06;
}
.music-panel-body>ul>li {
    position: relative;
    height: 60px;
    border-bottom: 1px solid #cfced3;
}
.music-check, .music-icon {
    position: absolute;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
}
.music-icon {
    top: 6px;
    width: 36px;
    height: 47px;
    background-image: url(/image/icon/music.png);
}
.music-name {
    top: 10px;
}
.music-author, .music-name {
    position: absolute;
    display: block;
    left: 45px;
}
.music-panel-foot {
    position: relative;
    width: 100%;
    height: 80px;
    background: #fff;
    -webkit-box-shadow: 0 -1px 5px 0 hsla(0,0%,65%,.3);
    box-shadow: 0 -1px 5px 0 hsla(0,0%,65%,.3);
    border-radius: 0 0 8px 8px;
    display:flex;
    justify-content:flex-end;    
    align-items: center;
}
.theme-common-btn{
    width:80px;
    height:40px;
    line-height:40px;
    text-align:center;
    color:#fff;
    font-size:16px;
    background-color: #5dac06;
    letter-spacing: .5px;
    -webkit-box-shadow: inset rgba(0,0,0,.2) 0 -2px;
    box-shadow: inset 0 -2px rgba(0,0,0,.2);
    margin-right:10px;
    cursor:pointer;
}
.theme-del-btn{
    background-color: #fff;
    color:#333;
}
.music-check, .music-icon {
    position: absolute;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;    
    background-image:url('/image/icon/check.png');
}
.theme-action {
    color: #5CAC06;
    font-size: 14px;
}
.story-theme-action {
    position: absolute;
    bottom: 5px;
    left: 45px;
}