.app-menu {          
    position: absolute;
    top:20px;
    left:0;
    width:100%;
    z-index:1;
}
.app-menu ul {  
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;   
    height: 100%;   
    font-size: 18px;
    font-weight: 400;
    color: rgba(67, 67, 67, 1);
}

.menu-logo {
    width: 260px !important;
    height: 64px;
    /* background: #5ebae6;  */
    background-image: url(/image/icon/logo-white.svg);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: left center;
}
.app-menu li.menu-logo a{
    font-size:24px;
    font-weight: 600;
    color:#fff;
    letter-spacing:5px;
    text-align:left;
}
.menu-space {
    flex: 1;
}

.app-menu li {
    width: 95px;
    text-align: center;
    font-size: 18px;
    line-height: 71px;
    box-sizing: border-box;
}

.app-menu li i {
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.app-menu li a {
    text-decoration: none;
    height: 100%;
    font-size: 16px;
    color: #fff;
    display: block;
    cursor:pointer;
}
.app-menu ul li.active{
    font-weight:600;
}

.app-menu li:hover a {
    font-weight:600;
}

/* .app-menu li.active {
    border-bottom: 6px solid #FE0000;
} */

/* .app-menu.active:hover a {
    color: #FE0000 !important;
} */
.app-menu li.phone-logo,.app-menu li.phone-nav-icon{
    display:none;
}
.app-menu.header-menu{  
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: #fff;
    /* padding: 32px 0; */
    box-shadow: 0 10px 20px rgba(71,80,89,.15);
    -webkit-transform: translateY(-120px);
    transform: translateY(-120px);
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out; 
}
.app-menu.header-menu.active{
    -webkit-transform: translateY(0);
    transform: translateY(0);
    border-bottom:none;
}
.app-menu.header-menu.active ul{
    display:flex;
    align-items: center;
}
.app-menu.header-menu.active li a{   
     color:#475059;
}
.app-menu.header-menu.active li.menu-logo{
    background-image: url(/image/icon/logo-black.svg);   
}
/* .app-menu.header-menu.active li{
    line-height: inherit; 
} */
.app-menu li.login-text{
    font-size:16px;
    color:#fff;
}
.app-menu li.login-text span{
    cursor:pointer;
}
.app-menu.header-menu.active li.login-text{
    color:#475059;
    font-size:16px;
}
.login-state span{
    display:inline-block;    
}
.avatar-img{
    width: 40px;
    height: 40px;
    background: #efefef;
    background-size: 100% 100%;
    background-position: 50%;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}
.app-menu li.common-class span{
    font-size:16px;
    color:#fff;
    cursor: pointer;
}
.app-menu.header-menu.active li.common-class span{
    color:#475059;
}
@media screen and (max-width: 767px) { 
    .app-menu ul{
        height: 100%;
        display: flex;
        flex-flow: inherit; 
        align-items: flex-start;
        justify-content: space-between; 
    }
    .app-menu li{
        display:none;
    } 
    .app-menu li.menu-logo,.app-menu li.phone-nav-icon{
        display:block;
    }
    .app-menu li.menu-logo{
        background-image: url(/image/icon/logo-white.svg); 
        background-size: 80%;
        background-repeat: no-repeat;
        background-position: center left;        
        height: 45px; 
        width: 160px !important;
    }   
    .app-menu li.phone-nav-icon{
        background:url(/image/phone/phone-nav-menu.svg) no-repeat;       
        background-position: center; 
        background-size: 60%; 
        width:45px;
        height:40px; 
        /* margin-right: 15px;     */
    }    
    .app-menu.phone{
        width:100%;
        height:100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        background:linear-gradient(135deg,#1da2c5,#4642e9,#3c0684); 
        flex-flow: wrap;
        justify-content: center;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s;            
    } 
    .app-menu.phone li.menu-logo{
        display:none;
    }
    .app-menu.phone li.phone-nav-icon{
        display:none;
    }
    .app-menu.phone li.menu-space{
        display:none;
    }  
    .app-menu.phone li {
        display:block;
        height:auto;
        width: 100%;   
        line-height:45px;
    }     
    .app-menu.phone li.phone-logo{
        padding: 30px 20px 0;
    } 
    .app-menu.phone li.phone-logo div{
        width: 100%;
        border-bottom: 1px solid #fff;
        position: relative;
    }
    .app-menu.header-menu.active li.menu-logo{
        background-position: center left;
    }
    .app-menu.phone li.phone-logo a{
        width:157px;
        height:54px;
        background-image:url(/image/icon/logo-white.svg);
        background-size:100%;        
        background-repeat: no-repeat;
        display:inline-block;
        background-position: bottom;
        /* font-size:32px;
        color:#fff;
        letter-spacing: 5px;  
        line-height:70px;       */
    }
    .app-menu.header-menu.active li.phone-nav-icon{
        background-image: url(/image/phone/phone-nav-menu2.svg);
        background-repeat: no-repeat;     
    }
    .app-menu.phone li.phone-logo img{
        padding:10px 10px;
        position: absolute;
        top:-15px;
        right: -10px;
        max-width:50px;
        max-height: 40px;    
    }  
    /* .phone-nav-close{
        padding:10px 10px;     
        right: -30px;
        max-height: 40px;
        top: -22px;
    } */
    .app-menu.phone li a {    
        height: auto;      
        color: #fff; 
        width: 100%;  
    }
    .app-menu.phone li:hover a,.app-menu.phone.active,.app-menu.phone.active:hover a {
        font-weight:600;
        color:#fff !important;
    } 
    .app-menu.phone li.download{
       display:none;
    }
}

@-webkit-keyframes navdown {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes navdown {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}
@-webkit-keyframes navup {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}

@keyframes navup {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
}